import React, { useState } from "react";
import Header from "./components/Header";
import "./App.css";

const initialArticles = [
  {
    id: 1,
    title: "Exploring Slovak Culture",
    image: "https://via.placeholder.com/300",
    content: "Discover the traditions and values of Slovakia.",
    date: "2024-12-01",
  },
  {
    id: 2,
    title: "Economic Growth in Slovakia",
    image: "https://via.placeholder.com/300",
    content: "A look at the thriving economy of Slovakia.",
    date: "2024-11-20",
  },
];

function App() {
  const [articles, setArticles] = useState(initialArticles);
  const [showForm, setShowForm] = useState(false);
  const [newArticle, setNewArticle] = useState({
    title: "",
    date: "",
    content: "",
    image: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArticle({ ...newArticle, [name]: value });
  };

  const handleAddArticle = () => {
    setArticles([
      ...articles,
      { ...newArticle, id: articles.length + 1, date: newArticle.date || new Date().toISOString().split("T")[0] },
    ]);
    setNewArticle({ title: "", date: "", content: "", image: "" });
    setShowForm(false);
  };

  return (
    <div>
      <Header />
      <h1>Slovakia Blog</h1>
      <div className="articles-container">
        {articles
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((article) => (
            <div key={article.id} className="article">
              {article.image && <img src={article.image} alt={article.title} />}
              <h3>{article.title}</h3>
              <p>{article.content}</p>
              <p className="date">Published on: {new Date(article.date).toDateString()}</p>
            </div>
          ))}
      </div>

      <button className="add-button" onClick={() => setShowForm(true)}>+</button>

      {showForm && (
        <div className="form-container">
          <h2>Add a New Article</h2>
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={newArticle.title}
            onChange={handleInputChange}
          />
          <input
            type="date"
            name="date"
            value={newArticle.date}
            onChange={handleInputChange}
          />
          <textarea
            name="content"
            placeholder="Content"
            value={newArticle.content}
            onChange={handleInputChange}
          ></textarea>
          <input
            type="text"
            name="image"
            placeholder="Image URL"
            value={newArticle.image}
            onChange={handleInputChange}
          />
          <button onClick={handleAddArticle}>Save</button>
          <button onClick={() => setShowForm(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default App;

