import React from "react";

function Header() {
const navItems = ["Culture", "Customs", "Economy"];
  return (
    <nav style={styles.navbar}>
      {navItems.map((item, index) => (
	      <a key={index} href="/" style={styles.navItem}>
          {item}
        </a>
      ))}
    </nav>
  );
}

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f8f8f8",
    padding: "10px 0",
    borderBottom: "1px solid #ddd",
  },
  navItem: {
    margin: "0 15px",
    textDecoration: "none",
    color: "#555",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
};

export default Header;

